import { AConfig } from "../classes/AConfig.js";
import { sleep } from "../core/AEngine.js";
import { MAP_OPTIONS } from "../core/maps/AMapStructs.js";
import { createMap, DefaultBounds } from "../utils/maps.js";
import { AKpiBlock } from "./AKpiBlock.js";
export class AKpiBlockMap extends AKpiBlock {
    set map(value) { PageScript.map = value; }
    get map() { return PageScript.map; }
    // public set SessionMarkers(value: { [NodeName: string]: AMarker }) { PageScript.SessionMarkers = value }
    // public get SessionMarkers(): { [NodeName: string]: AMarker } { return PageScript.SessionMarkers }
    constructor(opt) {
        super({
            refreshImplemented: false,
            optionsImplemented: true,
            ...opt
        });
        this.SessionMarkers = {};
    }
    async init() {
        super.init();
        // const $kpiView = this.$.closest('.kpi-view')
        // this.$.width($kpiView.width()!)
        // this.$.height($kpiView.height()!)
        this.map = createMap('#kpi-map', {
            zoom: AConfig.get(`general.map.mapZoom`, 16),
            // center: DefaultPosition(),
            streetViewControl: false,
        });
        this.map.fitBounds(DefaultBounds());
        if (!this.SessionMarkers) {
            this.SessionMarkers = {};
        }
        mapHelperService.displaySessionsOnMap({
            interpolate: true,
            sessions: this.SessionMarkers,
            map: this.map
        });
        await mapHelperService.prepareMapItems(MAP_OPTIONS.None, {
            showLegend: false,
            allowExport: false,
            map: this.map
        });
        sleep(10).then(() => {
            this.$filter.removeClass('map-requires-init');
            google.maps.event.trigger(this.map, 'tilesloaded');
        });
    }
    async refresh(filters = this.filterOption) { }
    getFilterOptions() {
        return {};
    }
    async render() {
        return await super.renderView({
            title: 'Map',
            padding: 0,
            // margin: 0,
            filterHtml: null,
            viewHtml: ( /*html*/`
        <div id="kpi-map" class="aci-map map-sm map-requires-init" style=""></div>
      `),
        });
    }
}
