import { ADynamicChartSnapshotAggregation } from "../../charts/ADynamicChartSnapshotAggregation.js";
import { ADynamicChartSnapshotDetections } from "../../charts/ADynamicChartSnapshotDetections.js";
import { ADynamicChartUtils } from "../../charts/ADynamicChartUtils.js";
import { AError } from "../../classes/AError.js";
import { AForm } from "../../core/form/AForm.js";
import { COLUMN_ACTION, COLUMN_DATETIME, COLUMN_HIDDEN, DATA_DATETIME } from "../../classes/AGridTypes.js";
import { AEngine, sleep } from "../../core/AEngine.js";
import { AKpiBlock } from "../../kpi/AKpiBlock.js";
import { ALERT_BUTTONS, ALERT_STATUS, ALERT_TITLES } from "../../services/AAlertService.js";
import { ADragService } from "../../services/ADragService.js";
import { EVENTS } from "../../services/AEventService.js";
import { AConvertToGridColumns, AConvertToGridData, appendResponseRows, AShowTable, flipInputValues } from "../../utils/tools.js";
import { AFormInstance } from "../../core/form/AFormInstance.js";
export class APage {
    get snapshot() { return (this.DataSource === 'detections' ? this.snapshotDetections : this.snapshotAggregation); }
    get isEditing() { return this._editing !== undefined; }
    constructor() {
        this.onStateChangeMap = {
            'tab-kpi-form': () => { filterService.setActive(false, { silent: true }); },
            'tab-kpi-list': () => { filterService.setActive(true); },
        };
        // @ts-ignore // TODO: FIX HIGHCHARTS
        const calcDimensions = () => ({ height: $('.flex-content').height() - 110, width: $(`#${this.snapshot.chart?.id}`).width() });
        this.snapshotAggregation = new ADynamicChartSnapshotAggregation('Chart', { calcDimensions });
        this.snapshotDetections = new ADynamicChartSnapshotDetections('Chart', { calcDimensions });
    }
    async init() {
        await this.reloadListAndFilters();
        FilterManager.load();
        const $print = $('#print');
        const $export = $('#export');
        // @ts-ignore // TODO: FIX HIGHCHARTS
        $print.on('click', _ => this.getChart()?.print());
        Events.on(`ACI_TABS_CHANGED->charts`, ({ tabview, $tabview }) => {
            $('.flex-content').css({ 'background-color': (tabview === 'tab-table') ? 'inherit' : '#fff' });
            $print.toggleClass('hidden', (tabview === 'tab-table'));
            $export.toggleClass('hidden', !(tabview === 'tab-table'));
        });
        this.createResizeListener();
        AEngine.get(ADragService).createDraggableGeneric({
            directParent: '.splitter',
            defaultSize: ['45%', '55%']
        });
        $('#create-kpi').on('click', _ => this.displayCreateOrEditTab());
        $('#RefreshButton').on('click', _ => FilterManager.showFilterWarning().then(_ => Loading.waitForPromises(this.refresh())));
    }
    updateDataSource(opt) {
        this.DataSource = opt.DataSource;
    }
    createResizeListener() {
        Events.on(EVENTS.CONTENT_RESIZE, () => this.resize().catch(AError.handle));
        Events.on(`ACI_TABS_CHANGED->charts->tab-chart`, () => this.resize().catch(AError.handle));
    }
    async resize() {
        await sleep(1);
        this.snapshot.resize();
    }
    getChart() {
        return this.snapshot.chart;
    }
    async reloadListAndFilters(kpiToSelect) {
        const res = await Loading.waitForPromises(widgetService.fetchAllCustomCharts());
        // Update kpi options
        const $kpi = $('#Kpi');
        $kpi.find('option:not([value="%"])').remove();
        $kpi.append(res.map(({ Name }) => `<option value="${Name}">${Name}</option>`).join(''));
        const { Original } = res;
        appendResponseRows(Original, ['ActionEdit', 'ActionDelete']);
        this.gridKpis = AShowTable({
            appendTo: 'table-custom-charts',
            aci: {
                resizeToFit: true,
                resizeToFitReverse: true,
                flex: 1,
            },
            selectionMode: {
                multiSelect: false,
                cell: false,
                row: true,
            },
            columns: AConvertToGridColumns(Original, {
                // TODO: Auto translate using request.query Translate member
                'Name': { text: await Translate.get('Name Kpi') },
                'ChartType': COLUMN_HIDDEN,
                'Horizontal': COLUMN_HIDDEN,
                'Vertical': COLUMN_HIDDEN,
                'Polar': COLUMN_HIDDEN,
                'Inverted': COLUMN_HIDDEN,
                'IncludeTotals': COLUMN_HIDDEN,
                'Filters': COLUMN_HIDDEN,
                'ShowLegend': COLUMN_HIDDEN,
                'CreatedAt': { ...COLUMN_DATETIME, ...COLUMN_HIDDEN },
                'DataSource': COLUMN_HIDDEN,
                'KeyY': COLUMN_HIDDEN,
                'ActionEdit': COLUMN_ACTION({ iconCls: 'fa-solid fa-pencil text-primary', btnCls: 'btn-white' }),
                'ActionDelete': COLUMN_ACTION({ iconCls: 'fa-regular fa-trash text-red', btnCls: 'btn-white' }),
            }),
            data: AConvertToGridData(Original, {
                'CreatedAt': DATA_DATETIME,
                'IncludeTotals': (v) => v === 1 ? true : false,
                'Inverted': (v) => v === 1 ? true : false,
                'Polar': (v) => v === 1 ? true : false,
            }),
        });
        if (kpiToSelect) {
            await this.selectKpiName(kpiToSelect);
        }
        this.gridKpis.on('selectionchange', (ev) => {
            console.log(ev.action, ev);
            if (ev.action === 'select') {
                this.viewKpi(ev.selection[0].data);
            }
        });
        this.gridKpis.on('cellclick', ({ record, column }) => {
            if (record.id && record.id.indexOf('group-header') !== -1) {
                return;
            }
            switch (column.data.field) {
                case 'ActionEdit':
                    this.displayCreateOrEditTab(record);
                    break;
                case 'ActionDelete':
                    this.displayDeleteModal(record);
                    break;
            }
        });
    }
    async selectKpiName(kpiName) {
        const records = this.gridKpis.store.records;
        let recordToSelect = records.find(r => r.data.Name === kpiName);
        if (!recordToSelect && records.length > 0) {
            recordToSelect = this.gridKpis.store.last;
        }
        if (recordToSelect) {
            this.gridKpis.selectedRecord = recordToSelect;
            $('#Kpi').val(recordToSelect.data.Name);
            AEngine.log('%pPageScript.selectKpiName %cCalled!');
            await this.refresh();
        }
    }
    async genFormInputs() {
        const chartTypes = [
            { id: 'column', text: 'Column Chart' },
            { id: 'bar', text: 'Bar Chart' },
            { id: 'line', text: 'Line Chart' },
            { id: 'spline', text: 'Spline Chart' },
            { id: 'area', text: 'Area Chart' },
            { id: 'areaspline', text: 'Area Spline Chart' },
            { id: 'scatter', text: 'Scatter Chart' },
            { id: 'pie', text: 'Pie Chart' },
        ];
        const axisTypes = Object.keys(ADynamicChartSnapshotDetections.selections).map((key) => {
            return { id: key, text: key.replace('Chart_', '') };
        });
        const dateRangeOptions = Object.keys(AKpiBlock.defaultDateRangeOptions).map(key => {
            return { id: key, text: key };
        });
        const sourceOptions = [
            { id: 'aggregation', text: 'aggregation' },
            { id: 'detections', text: 'detections' },
        ];
        const allOption = { id: 'All', text: 'All' };
        const seperatorOption = { id: '', text: '______' };
        let verticalAxis = ADynamicChartUtils.dynamicChartSettings.verticalAxis.map(({ key, text }) => ({ id: key, text }));
        let serieOptions = ADynamicChartUtils.dynamicChartSettings.mapTo.map(({ key, text }) => ({ id: key, text }));
        let horizOptions = ADynamicChartUtils.dynamicChartSettings.horizontalAxis.map(({ key, text }) => ({ id: key, text }));
        const dynamicFormInputs = {
            'detections': [
                { id: 'KeyY', label: 'Y Axis', type: 'select', options: [verticalAxis[0]], width: 'col-6', disabled: true },
                { id: 'Horizontal', label: 'X Axis', type: 'select', options: axisTypes, width: 'col-5', },
                { id: 'FlipAxis', label: 'Flip Axis', type: 'button', cls: 'btn-white col-12 mt-2', width: 'col-2', iconCls: 'fa-solid fa-repeat', onClick: ($form) => flipInputValues('#Horizontal', '#Vertical', $form) },
                { id: 'Vertical', label: 'Series', type: 'select', options: axisTypes, width: 'col-5' },
            ],
            'aggregation': [
                { id: 'KeyY', label: 'Y Axis', type: 'select', options: verticalAxis, width: 'col-6', disabled: false },
                { id: 'Horizontal', label: 'X Axis', type: 'select', options: [allOption, seperatorOption, ...horizOptions, seperatorOption, ...serieOptions], value: horizOptions[0].id, width: 'col-5' },
                { id: 'FlipAxis', label: 'Flip Axis', type: 'button', cls: 'btn-white col-12 mt-2', width: 'col-2', iconCls: 'fa-solid fa-repeat', onClick: ($form) => flipInputValues('#Horizontal', '#Vertical', $form) },
                { id: 'Vertical', label: 'Series', type: 'select', options: [allOption, seperatorOption, ...horizOptions, seperatorOption, ...serieOptions], value: allOption.id, width: 'col-5' },
            ]
        };
        const formInputs = [
            { id: 'Preview', label: 'Preview Range', type: 'select', options: dateRangeOptions },
            { type: 'seperator' },
            { id: 'Name', minlength: 3, maxlength: 64, type: 'text', disabled: this.isEditing, regexAllow: /^[#A-Za-z0-9\s]*$/, width: 'col-6' },
            { id: 'DataSource', type: 'select', options: sourceOptions, value: this.DataSource, onChange: (input, inputs) => this.onDataSourceChange(input, inputs), width: 'col-6', disabled: true },
            { id: 'ChartType', type: 'select', options: chartTypes, width: 'col-6' },
            ...dynamicFormInputs[this.DataSource],
            { type: 'seperator' },
            { id: 'ShowLegend', type: 'checkbox', value: true, width: 'col-6' },
            { id: 'IncludeTotals', label: 'Show Totals', type: 'checkbox', width: 'col-6' },
            { id: 'Inverted', type: 'checkbox', width: 'col-6' },
            { id: 'Polar', type: 'checkbox', width: 'col-6' },
            // { id: 'IgnoreOutsideSegment', label: 'Ignore outside segment', type: 'checkbox', width: 'col-12' },
            // { id: 'OnlyAllowFinalized', label: 'Only allow finalized', type: 'checkbox', width: 'col-12' },
            // {
            //   id: 'AddCondition', hideLabel: true, type: 'button', cls: 'btn-white col-12 mt-2', iconCls: 'fa-solid fa-filter',
            //   onClick: ($form: JQuery) => flipInputValues('#Horizontal', '#Vertical', $form)
            // },
            // { id: 'ConditionField1', type: 'select', options: ['ParkingRight', 'Verification', 'DetectionState'], width: 'col-4' },
            { type: 'seperator' },
            {
                id: 'fVerificationEnabled', label: '', type: 'checkbox', width: 'col-auto', hideLabel: true,
                onChange: (input, inputs) => {
                    if (this.snapshot?.isMounted()) {
                        this.snapshot.destroy();
                    }
                    const formInputMap = {
                        setActive: ['fVerificationOperator', 'fVerificationCollection'],
                    };
                    inputs.filter(inp => formInputMap.setActive.includes(inp.id)).map(inp => {
                        inp.setActive(input.$input.prop('checked'));
                    });
                }
            },
            { id: 'fVerificationField', label: 'Filter', type: 'select', options: ['Verification'], disabled: true, width: 'col-4', cls: { label: 'hidden' } },
            { id: 'fVerificationOperator', label: '', type: 'select', options: ['NOT EQUALS', 'EQUALS'], width: 'col', cls: { label: 'hidden' } },
            {
                id: 'fVerificationCollection',
                label: '',
                type: 'treedropdown',
                options: verificationRef,
                disallowAll: true,
                disallowNone: true,
                width: 'col-4',
                cls: { label: 'hidden' }
            },
            { type: 'seperator', cls: 'invisible', style: 'margin: 0;' },
            {
                id: 'fDetectionStateEnabled', label: '', type: 'checkbox', width: 'col-auto',
                onChange: (input, inputs) => {
                    if (this.snapshot?.isMounted()) {
                        this.snapshot.destroy();
                    }
                    const formInputMap = {
                        setActive: ['fDetectionStateOperator', 'fDetectionStateCollection'], // ['Horizontal', 'Vertical', 'FlipAxis', '_KeyY', 'IncludeTotals']
                    };
                    inputs.filter(inp => formInputMap.setActive.includes(inp.id)).map(inp => {
                        inp.setActive(input.$input.prop('checked'));
                    });
                }
            },
            { id: 'fDetectionStateField', label: 'Filter', type: 'select', options: ['DetectionState'], disabled: true, width: 'col-4', cls: { label: 'hidden' } },
            { id: 'fDetectionStateOperator', label: '', type: 'select', options: ['NOT EQUALS', 'EQUALS'], width: 'col', cls: { label: 'hidden' } },
            {
                id: 'fDetectionStateCollection',
                label: '',
                type: 'treedropdown',
                options: detectionStateRef,
                disallowAll: true,
                disallowNone: true,
                width: 'col-4',
                cls: { label: 'hidden' }
            },
            { type: 'seperator' },
            // {
            //   id: 'Collection',
            //   type: 'multiselect',
            //   disallowNone: true,
            //   options: await Loading.waitForPromises(Object.keys(detectionStateRef.Options).map(async (key) => {
            //     const v = detectionStateRef.Options[key]
            //     return {
            //       id: v.Key,
            //       text: await Translate.get(v.KeyShort),
            //       checked: true
            //     }
            //   })),
            //   width: 'col-4'
            // },
        ];
        return formInputs;
    }
    onDataSourceChange(input, inputs) {
        console.log('changed!', input.id);
        if (this.snapshot?.isMounted()) {
            this.snapshot.destroy();
        }
        const formInputMap = {
            aggregation: {
                hide: ['IncludeTotals'], // ['Horizontal', 'Vertical', 'FlipAxis', '_KeyY', 'IncludeTotals']
            },
            detections: {
                hide: [], // ['Horizontal2', 'Vertical2', 'FlipAxis2', 'KeyY']
            },
        };
        const { $input } = input;
        const currentMap = formInputMap[$input.val()];
        inputs.map(inp => {
            inp.toggle(!currentMap.hide.includes(inp.id));
        });
    }
    async createOrEdit(record) {
        if (record) {
            $('#Kpi').val(record.data.Name);
        }
        await this.changePageState({ tab: 'tab-kpi-form', resetEditing: false });
        const $tabview = $(`[tabview="tab-kpi-form"]`).eq(0);
        const formInputs = await this.genFormInputs();
        const form = new AFormInstance({
            formInputs,
            ignoreWildcards: true,
        });
        const $form = await Loading.waitForPromises(form.generate({ translate: true, wrapInColumns: true }));
        // const $form: JQuery = await Loading.waitForPromises(AForm.genForm(formInputs, { translate: true, wrapInColumns: true }))
        $form.css({ 'position': 'relative', 'padding-top': '15px' });
        $form.attr('id', 'form-kpi');
        $form.addClass('h-padding');
        $form.prepend(/*html*/ `<div class="floating-close-btn"><i class="fa-solid fa-xmark fa-fw"></i></div>`);
        const formData = record?.data ?? {};
        // const detectionState = record?.data.Filters.find(f => f.Field === 'DetectionState')
        const Filters = record?.data.Filters ?? [];
        Filters.map(f => {
            formData[`f${f.Field}Enabled`] = true;
            formData[`f${f.Field}Operator`] = f.Operator;
            formData[`f${f.Field}Collection`] = f.Values;
        });
        await form.injectFormData({ formData });
        await form.initFormValidation();
        // await AForm.injectFormData($form, {formData, formInputs})
        // await AForm.initFormValidation($form, formInputs)
        $tabview.html('');
        $tabview.append($form);
        const $name = $form.find('#Name');
        $form.find('#Preview').val(AEngine.isDevelopmentMode ? 'last 7 days' : 'today');
        const $chartType = $form.find('#ChartType');
        $chartType.on('change', (e) => this.updateFilterAvailability($form, $chartType));
        this.updateFilterAvailability($form, $chartType);
        const reload = () => {
            const rangeKey = $form.find('#Preview').val();
            const { FromDate, ToDate } = AKpiBlock.defaultDateRangeOptions[rangeKey].getFilters();
            const options = this.extractFormData($form, false);
            if (options) {
                Loading.waitForPromises(this.refreshChart({ FromDate, ToDate, DeviceName: '%' }, options)).catch(AError.handle);
            }
        };
        const enableAutoReload = () => {
            const $inputArr = $form.find(':input').toArray().map(e => $(e));
            $inputArr.map($input => {
                $input.on('change', (e) => { reload(); });
            });
            $name.trigger('change');
        };
        $('#save-kpi').off('click');
        $('#cancel-kpi').off('click');
        $('#preview-kpi').off('click');
        $('#preview-kpi').on('click', () => {
            // $('#preview-kpi').toggleClass('hidden')
            // $('#save-kpi').toggleClass('hidden')
            reload();
        });
        $('#save-kpi').on('click', () => Loading.waitForPromises(this.onSaveClicked($form)));
        $('#cancel-kpi').on('click', _ => {
            return this.changePageState({
                tab: 'tab-kpi-list',
                resetEditing: true
            });
        });
        $form.find('.floating-close-btn').on('click', _ => {
            return this.changePageState({
                tab: "tab-kpi-list",
                resetEditing: true
            });
        });
        return { $form, enableAutoReload, reload };
    }
    async onSaveClicked($form) {
        try {
            const options = this.extractFormData($form, true);
            if (options !== undefined) {
                if (!this.isEditing && await widgetService.customChartExists(options)) {
                    $('#form-kpi #Name').addClass('is-error');
                    return Alerts.show({
                        title: ALERT_TITLES.Warning,
                        content: await Translate.get(`KPI Name is already is use!`)
                    });
                }
                await Loading.waitForPromises(widgetService.createCustomChart(options));
                await this.reloadListAndFilters(options.Name);
                await this.changePageState({
                    tab: 'tab-kpi-list',
                    resetEditing: true
                });
            }
        }
        catch (err) {
            AError.handle(err);
        }
    }
    updateFilterAvailability($form, $chartType) {
        $form.find('#Horizontal').prop('disabled', ($chartType.val() === 'pie'));
        if ($chartType.val() === 'pie') {
            $form.find('#Horizontal').val($('#Horizontal option:first-child').attr('value'));
        }
    }
    extractFormData($form, showErrorOnIncomplete) {
        console.warn('// TODO: Find out why AForm.extractFormData isnt being used');
        // if ($form.find('.is-error').length > 0 && showErrorOnIncomplete) {
        if (!AFormInstance.validate($form) && showErrorOnIncomplete) {
            Alerts.incomplete();
            return;
        }
        // const options: AKpiConfigCustomOptions = <any>Object.fromEntries(new FormData($form.get(0) as HTMLFormElement))
        const options = AForm.extractFormData($form, {
            ignoreWildcards: false,
            ignoreInvisible: true
        });
        // Ensure that name is set, because if it has disabled attribute, it doesn't parse
        options.Name = $form.find('#Name').val();
        const possibleFilters = ['Verification', 'DetectionState'];
        options.Filters = possibleFilters.map(key => {
            return (options[`f${key}Enabled`] ? [{ Field: options[`f${key}Field`], Operator: options[`f${key}Operator`], Values: options[`f${key}Collection`] }] : []);
        }).flat();
        // $form.find('[id]').toArray().map(e => $(e))
        //   .filter($i => $i.attr('type') === 'checkbox')
        //   .map($checkbox => options[$checkbox.attr('name')!] = $checkbox.prop('checked'))
        return options;
    }
    viewKpi(record) {
        $('#Kpi').val(record.Name);
        this._editing = undefined;
        Loading.waitForPromises(this.refresh()).catch(AError.handle);
    }
    async displayCreateOrEditTab(record) {
        // $('#save-kpi').toggleClass('hidden', true)
        // $('#preview-kpi').toggleClass('hidden', false)
        this.updateDataSource({ DataSource: (record !== undefined) ? record.data.DataSource : $('#kpi-datasource').val() ?? 'detections' });
        this._editing = record;
        const { enableAutoReload, reload } = await Loading.waitForPromises(this.createOrEdit(record));
        // reload()
        // enableAutoReload()
    }
    async displayDeleteModal(record) {
        const alert = Alerts.show({
            translatedTitle: await Loading.waitForPromises(Translate.get('Delete Custom Chart')),
            buttons: ALERT_BUTTONS.yesNo,
            content: await Loading.waitForPromises(Translate.get(/*html*/ `
        Are you sure you want to delete this Custom Chart?
      `))
        });
        alert.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
            try {
                record.remove();
                await Loading.waitForPromises(widgetService.deleteCustomChart(record.data));
                await Loading.waitForPromises(this.reloadListAndFilters(this.gridKpis.store.last?.data.Name));
            }
            catch (err) {
                AError.handle(err);
            }
            finally {
                Loading.waitForPromises(this.refresh());
            }
        });
    }
    async changePageState(opt) {
        const $tab = $(`[tab="${opt.tab}"]`);
        if ($tab.length === 0) {
            AError.handle(`Couldn't find tab [tab="${opt.tab}"]`);
        }
        $tab.trigger('click');
        if (opt.resetEditing) {
            if (this._editing) {
                await Loading.waitForPromises(this.reloadListAndFilters(this._editing.data.Name));
            }
            this._editing = undefined;
        }
        this.onStateChangeMap[opt.tab](opt.tab);
    }
    async refreshChart(filters, options) {
        this.updateDataSource(options);
        const { hasData, chartData } = await this.snapshot.update(options, filters);
        this.snapshot.setTitle(options.Name);
        this.grid = AShowTable({
            appendTo: 'table-bryntum',
            columns: AConvertToGridColumns(chartData, {
                'Bars': { text: await Translate.get(options.Vertical.replace('Chart_', '')) },
            }),
            data: AConvertToGridData(chartData)
        });
        $('#print').prop('disabled', false);
        Events.tryInvoke(EVENTS.CONTENT_RESIZE);
    }
    async refresh(kpiToSelect) {
        try {
            const { FromDate, ToDate, Kpi } = FilterManager.saveExplicit();
            const found = this.gridKpis.store.find((v) => v.data.Name === (kpiToSelect ?? Kpi));
            if (found === undefined) {
                return Alerts.show({
                    title: ALERT_TITLES.Warning,
                    buttons: ALERT_BUTTONS.ok,
                    content: await Translate.get(`No Kpi Selected!`)
                });
            }
            FilterManager.setActive(false);
            const { Name, DataSource, IncludeTotals, Horizontal, Vertical, KeyY, ChartType, Inverted, Polar, IgnoreOutsideSegment, OnlyAllowFinalized, ShowLegend, Filters } = found.data;
            const chartOptions = { DataSource, IncludeTotals, Horizontal, Vertical, KeyY, ChartType, Inverted, Polar, IgnoreOutsideSegment, OnlyAllowFinalized, ShowLegend, Filters };
            const chartFilters = { FromDate, ToDate, DeviceName: '%' };
            this.updateDataSource({ DataSource });
            const { hasData, chartData } = await this.snapshot.update(chartOptions, chartFilters);
            this.snapshot.setTitle(Name);
            Events.tryInvoke(EVENTS.CONTENT_RESIZE);
            this.grid = AShowTable({
                appendTo: 'table-bryntum',
                columns: AConvertToGridColumns(chartData, {
                    'Bars': { text: await Translate.get(Vertical.replace('Chart_', '')) },
                }),
                data: AConvertToGridData(chartData)
            });
            $('#print').prop('disabled', false);
        }
        catch (err) {
            AError.handle(err);
        }
        finally {
            FilterManager.setActive(true);
        }
    }
}
export function css() {
    return ( /*html*/`
    <style>
    .highcharts-container .aci-hover-item td {
      border: 4px solid transparent;
    }
    *[tabgroup="charts"][tabview] {
      height: 100%;
    }
    .flex-content {
      background-color: #fff;
    }
    .px-3 {
      padding-left: 1.2rem;
      padding-right: 1.2rem;
    }
    </style>
  `);
}
export function render() {
    return ( /*html*/`
    <form id="Filters" class="filter-bar side-filter-bar filter-disable-profile-buttons columns">
      <div class="column c-scroll col-12">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input" type="time" id="FromTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input" type="time" id="ToTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="Kpi">kpi</label>
          <select class="form-select" id="Kpi">
            <option value="%">None</option>
          </select>
        </div>

      </div>
      <div class="column col-12">
        <div class="columns">
          <!--
          <div class="column col-12">
            <button class="btn btn-grey btn-sm col-12 mb-2" id="FlipAxis">Flip Axis</button>
          </div>
          <div class="column col-12">
            <button class="btn btn-success btn-sm col-12 mb-2" id="SaveKpi">Save As Kpi</button>
          </div>
          -->
          <div class="column col-12">
            <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
          </div>
        </div>
      </div>
    </form>
    <div id="Rapport" class="flex-child fh">
      <div class="flex-content">
        <div class="splitter two-split fh" style="overflow-y: auto; overflow-x: hidden;">

          <div class="drag-section part-one" style="width: 45%">
            <!-- -->
            <div class="bryntum-container has-footer-2">
              <div class="fh" style="overflow-y: auto">
                <div class="aci-tabs hidden" tabgroup="views">
                  <button class="aci-tab active" tab="tab-kpi-list"><span>Kpi List</span></button>
                  <button class="aci-tab" tab="tab-kpi-form"><span>Kpi Form</span></button>
                </div>
                <div class="columns col-gapless fh">
                  <div class="column col-12">
                    <div tabgroup="views" tabview="tab-kpi-list" class="fh">
                      <div id="table-custom-charts" class="fh"></div>
                    </div>
                    <div tabgroup="views" tabview="tab-kpi-form" class="fh" style="overflow-y: auto;">
                    </div>
                  </div>
                </div>
              </div>

              <div tabgroup="views" tabview="tab-kpi-list">
                <div class="columns footer aci">
                  <div class="column col-12">
                    <div class="input-group">
                      <select id="kpi-datasource" class="form-select">
                        <option value="detections">Detection Chart</option>
                        <option value="aggregation">Aggregation Chart</option>
                      </select>
                      <button id="create-kpi" class="btn btn-primary input-group-btn">
                        <div class="px-3">
                          <i class="fa-regular fa-plus"></i>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div tabgroup="views" tabview="tab-kpi-form" style="overflow-y: auto;">
                <div class="columns footer aci">
                  <div class="column col-4">
                    <button id="save-kpi" class="btn btn-success fw">
                      <i class="fa-solid fa-sd-card"></i>
                      Save
                    </button>
                  </div>
                  <div class="column col-4">
                    <button id="preview-kpi" class="btn btn-primary fw">
                      <i class="fa-solid fa-eye"></i>
                      Preview
                    </button>
                  </div>
                  <div class="column col-4">
                    <button id="cancel-kpi" class="btn btn-grey fw">
                      <i class="fa-solid fa-ban"></i>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- -->
          </div>
          <div id="separator1" class="drag-seperator separator"></div>

          <div class="drag-section part-two" style="width: 55%">
            <div class="bryntum-container has-footer-2">
              <div class="fh" style="overflow-y: auto">
                <div class="aci-tabs tabs-sticky tabs-fixed-md top-zero tabs-fw" tabgroup="charts">
                  <button class="aci-tab active" tab="tab-chart"><span>Chart</span></button>
                  <button class="aci-tab" tab="tab-table"><span>Table</span></button>
                </div>
                <div class="columns col-gapless" style="height: calc(100% - 60px);">
                  <div class="column col-12">
                    <div tabgroup="charts" tabview="tab-chart" class="h-padding">
                      <div id="Chart" class="w3-animate-zoom"></div>
                    </div>
                    <div tabgroup="charts" tabview="tab-table" class="h-padding">
                      <div id="table-bryntum" class="fh"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="columns footer aci">
                <div class="column col-auto col-ml-auto">
                  <button id="print" style="min-width: 200px" class="btn btn-primary" disabled="disabled">Print</button>
                  <button id="export" style="min-width: 200px" class="btn btn-primary hidden" disabled="disabled">Export</button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
  `);
}
