import { AConfig } from "../../classes/AConfig.js";
import { AError, ERROR_GROUPS } from "../../classes/AError.js";
import { AExportMap } from "../../classes/AExportMap.js";
import { genLegend, getFromOldConfig } from "../../classes/ALegend.js";
import { AResponse } from "../../classes/AResponse.js";
import { AEngine } from "../../core/AEngine.js";
import { AFormInstance } from "../../core/form/AFormInstance.js";
import { MAP_OPTIONS } from "../../core/maps/AMapStructs.js";
import { AStatisticsService } from "../../services/AStatisticsService.js";
import { AStatisticsSimpleService } from "../../services/AStatisticsSimpleService.js";
import { createMap } from "../../utils/maps.js";
import { ACamel, ARound, asyncMapArray, compareObjects } from "../../utils/tools.js";
export class APage {
    constructor() {
        this.precalc = null; // = {min: 0, max: 100, unit: '%'}
        this.precalcFallback = { min: 1, max: 99, left: 1, right: 99, unit: '', hideOutsideBounds: false };
        this.categories = [
            { label: 'Occupancy', value: 'Occupancy' },
            { label: 'VisitorRate', value: 'VisitorRate' },
            { label: 'Compliancy', value: 'Compliancy' },
            { label: 'CompliancyVisitor', value: 'CompliancyVisitor' },
            { label: 'EnforcementIntensity', value: 'EnforcementIntensity' },
        ];
        this.strokes = {};
        this.visibleStrokes = {};
        this.weeks = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        this.months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        this.translationKeys = ['Occupancy', 'Occupancy', 'VisitorRate', 'Compliancy', 'CompliancyVisitor', 'EnforcementIntensity', 'TimeFrame', 'Passes', 'Max Passes', 'Parking Pressure', 'MaxParkingSpaces', 'Unknown'];
        this.statistics = {};
        this.prevStatistics = {};
        this.SessionMarkers = {};
        this.ignoreDetectionsOutsideSegment = false;
        this.map = createMap('map', {
            zoom: 24,
            streetViewControl: true,
            // styles: [
            //   {featureType: "road", elementType: "labels", stylers: [{ "visibility": "on" }] },
            //   {featureType: "poi", stylers: [{ "visibility": "off" }] },
            //   {featureType: "administrative", stylers: [{ "visibility": "off" }] },
            //   {featureType: "road", elementType: "geometry.fill", stylers: [{ "color": "#dadada" }, { "weight": 1 }] },
            //   {featureType: "road", elementType: "geometry.stroke", stylers: [{ "color": "#dadada" }, { "weight": 0.8 }] },
            //   {featureType: "landscape", stylers: [{ "color": "#ffffff" }] },
            //   {featureType: "water", stylers: [{ "visibility": "on" }] },
            //   {featureType: "transit", stylers: [{ "visibility": "off" }] },
            //   {elementType: "labels", stylers: [{ "visibility": "off" }] },
            //   {elementType: "labels.text", stylers: [{ "visibility": "on" }] },
            //   {elementType: "labels.text.stroke", stylers: [{ "color": "#ffffff" }] },
            //   {elementType: "labels.text.fill", stylers: [{ "color": "#000000" }] },
            //   {elementType: "labels.icon", stylers: [{ "visibility": "off" }] }
            // ]
        });
        $('#RefreshButton').on('click', _ => FilterManager.showFilterWarning().then(_ => Loading.waitForPromises(this.refresh())));
    }
    async init() {
        try {
            FilterManager.setActive(false);
            FilterManager.load();
            mapHelperService.prepareMapItems(MAP_OPTIONS.All, {
                showSearch: true,
                lockPreferences: {
                    mapStyle: '1',
                    labelDensity: 'high',
                    landmarkDensity: 'low'
                }
            }).catch(AError.handle);
            mapHelperService.displaySessionsOnMap({
                interpolate: false,
                sessions: this.SessionMarkers,
                map: this.map,
            });
            this.map.fit();
            await Loading.waitForPromises([
                Translate.get(this.weeks).then(data => this.weeksTranslated = data),
                Translate.get(this.months).then(data => this.monthsTranslated = data),
                Translate.get(this.translationKeys).then(data => this.translations = data),
            ]);
            await Loading.waitForPromises(this.loadWaySegments());
            FilterManager.setActive(true);
        }
        catch (err) {
            AError.handle(err);
        }
    }
    get category() {
        const dflt = this.categories[0].value;
        return (this.baseForm) ? this.baseForm?.extractFormData({ cleanData: true })?.category || dflt : dflt;
    }
    categoryToConfigKey(category) {
        switch (category) {
            case 'Occupancy': return 'thematic.occupancy';
            case 'VisitorRate': return 'thematic.visitorRate';
            case 'Compliancy': return 'thematic.compliancy';
            case 'CompliancyVisitor': return 'thematic.compliancyVisitor';
            case 'EnforcementIntensity': return 'thematic.enforcementIntensity';
        }
        AError.handleSilent(`"${category}" is not a valid category`, ERROR_GROUPS.InvalidDetectionStatisticsCategory);
        return 'thematic.compliancy';
        // switch (this.category) {
        //   case 'thematic.occupancy': return CHART_TYPE_ENUM.OCCUPANCY
        //   case 'thematic.visitorRate': return CHART_TYPE_ENUM.VISITOR_RATE
        //   case 'thematic.compliancy': return CHART_TYPE_ENUM.COMPLIANCY
        //   case 'thematic.compliancyVisitor': return CHART_TYPE_ENUM.COMPLIANCY_VISITOR
        //   case 'thematic.enforcementIntensity': return CHART_TYPE_ENUM.
        // }
    }
    buildProfile() {
        // TODO: Replace with new config code
        const cat = this.category;
        AConfig.get('TODO', 'TODO');
        // const transitionKey = this.categoryToConfigKey(cat)
        return {
            type: (cat === 'EnforcementIntensity') ? 'Exponential' : 'Linear',
            // transition: configService.get<AColorLerp>(transitionKey)!,
            transition: getFromOldConfig({
                colorsKey: `drawing & colors.thematic.${ACamel(cat)}.colors`,
                // boundsKey: `drawing & colors.thematic.${ACamel(this.category)}.bounds`,
                colorsFallback: ['#ff0000', '#32CD32'],
                boundsFallback: [0, 100],
            }),
            amountOfColors: (cat === 'EnforcementIntensity') ? 5 : 5,
            unit: (cat === 'EnforcementIntensity') ? '' : '%',
        };
    }
    async loadWaySegments() {
        const statisticsService = AEngine.get(AStatisticsService);
        const [res, segmentId, segmentToStreetName, segmentToZone] = await Loading.waitForPromises([
            requestService.query(`
        SELECT
          gw.WaySegmentId,
          Attributes,
          Name,
          ST_ASGEOJSON(gw.Bounds) as Bounds
        FROM geo_waysegments gw
        WHERE Active=1
      `),
            // statisticsService.fetchSegmentToWaySegmentId(),
            // statisticsService.fetchSegmentToStreetName(),
            // statisticsService.fetchSegmentToZone(),
            statisticsService.fetchGeoConnectionIds({
                FromGeoType: 'Segment',
                ToGeoType: 'WaySegment'
            }),
            statisticsService.fetchGeoConnectionNames({
                FromGeoType: 'Segment',
                ToGeoType: 'WaySegment'
            }),
            statisticsService.fetchGeoConnectionIds({
                FromGeoType: 'Segment',
                ToGeoType: 'Zone'
            })
        ]);
        const waysegments = new AResponse(res);
        this.segmentId = segmentId;
        this.segmentToStreetName = segmentToStreetName;
        this.segmentToZone = segmentToZone;
        this.strokes = {};
        waysegments.map(({ WaySegmentId, Bounds, Name }) => {
            if (Bounds.type !== 'LineString')
                return;
            const path = Bounds.coordinates.map(([lng, lat]) => new google.maps.LatLng(lat, lng));
            let stroke = new google.maps.Polyline({ path, strokeColor: '#000', strokeOpacity: 1, strokeWeight: 5, visible: false });
            stroke.data = {};
            stroke._data = { WaySegmentId, path };
            google.maps.event.addListener(stroke, 'click', async function (h) {
                try {
                    const page = PageScript;
                    const statsEntry = this._data.statsEntry;
                    const segmentIds = page.segmentId.imap[WaySegmentId] || null;
                    const streetname = page.segmentToStreetName.map[segmentIds] || '?';
                    const formulaHtml = await statsEntry.createFormulaHtml({
                        category: page.category,
                        precalc: page.precalc
                    });
                    const option = statsEntry.getStatsChartOption(page.category);
                    PageScript.chart = await statsEntry.createChart({
                        category: page.category,
                        title: streetname,
                        modalTitle: `#${WaySegmentId} ${streetname}`,
                        // precalculated: page.precalc,
                        formulaHtml // Don't disable hover, the customer needs to verify the Capacity/Occupancy =>  ,disableHover: (option === 'Occupancy')
                    }, option);
                }
                catch (err) {
                    AError.handle(err);
                }
            });
            stroke.setMap(this.map);
            this.strokes[WaySegmentId] = stroke;
        });
    }
    calcMinMaxEntries(opt) {
        const { statistics, waySegmentIds } = opt;
        const entryCounts = waySegmentIds.map((id) => statistics[id].getOccupancyEntryCount());
        let minEntries = Math.min.apply(null, entryCounts);
        let maxEntries = Math.max.apply(null, entryCounts);
        const min = (minEntries !== Infinity ? minEntries : undefined) ?? this.precalcFallback.min;
        const max = (maxEntries !== -Infinity ? maxEntries : undefined) ?? this.precalcFallback.max;
        return { min, left: min, right: max, max };
    }
    /**
     * Calculate additional data using statistics for example (min & max) for statistics
     */
    async preCalcData(args) {
        const { statistics, resetPrevValues } = args;
        let output = (resetPrevValues === true) ? { ...this.precalcFallback } : this.precalc;
        switch (this.category) {
            case 'EnforcementIntensity':
                output = Object.assign({}, output, this.calcMinMaxEntries({ statistics, waySegmentIds: Object.keys(statistics) }));
                break;
            default:
                output = null;
                break;
        }
        this.precalc = output;
        return this.precalc;
    }
    /**
     * Hides all visible strokes
     */
    hideVisibleStrokes() {
        // Benchmarked, most efficient loop
        var keys = Object.keys(this.visibleStrokes), i = keys.length;
        while (i--) {
            const visibleStroke = this.visibleStrokes[keys[i]];
            visibleStroke.setOptions({ visible: false });
        }
        this.visibleStrokes = {};
    }
    async drawStatistics() {
        const { category, statistics } = this;
        const coordinates = [];
        this.hideVisibleStrokes();
        const { minColor, opacity } = this.legend;
        const keys = Object.keys(statistics);
        await asyncMapArray(keys, 40, (waySegmentId) => {
            const statsEntry = statistics[waySegmentId];
            const stroke = this.strokes[waySegmentId];
            Object.assign(stroke._data, { statsEntry });
            const t = statsEntry.getDataByCategory({ category, precalc: this.precalc });
            if (t !== null) {
                const strokeColor = this.legend.calcColor({ value: t * 100.0 }).rgba(opacity);
                stroke.data = { [category]: ARound(t * 100, 2) };
                stroke.setOptions({ visible: true, strokeColor });
                this.visibleStrokes[waySegmentId] = stroke;
                coordinates.push(stroke._data.path[0]);
            }
            else if (this.precalc?.hideOutsideBounds === false) {
                stroke.data = {};
                stroke.setOptions({
                    visible: true,
                    strokeColor: minColor.rgba(opacity)
                });
                this.visibleStrokes[waySegmentId] = stroke;
            }
        });
        this.exportMap = new AExportMap(`thematic map ${category.toLowerCase()}`, { scales: true });
        this.exportMap.addLines(Object.values(this.visibleStrokes), {
            dontExportData: true
        });
        this.exportMap.allowExport();
        return { coordinates };
    }
    async onCategoryChanged() {
        this.legend.update({
            ...this.buildProfile()
            // TODO: Implement new config
            // transition: AConfigNew.get<AColorLerp>(`thematic.${ACamel(this.category)}` as AConfigKey)!
        });
        await this.preCalcData({ resetPrevValues: true, statistics: this.statistics });
        this.customForm = await this.genCustomForm();
        this.legend.setContent([this.customForm?.$form()]);
        this.legend.redrawLegend();
        return await this.drawStatistics();
    }
    async genBaseForm() {
        const form = new AFormInstance({
            // defaultValue: { category: this.category },
            // defau
            ignoreWildcards: true,
            formInputs: [
                {
                    id: 'category', type: 'select', cls: 'input-sm', options: this.categories.map(({ label, value }) => ({ id: value, text: label })),
                    onChange: (input) => {
                        const oldData = form.formData;
                        const newData = form.extractFormData({ cleanData: true, setInternalFormData: true });
                        const changes = compareObjects(oldData, newData);
                        AEngine.log('Changes', changes);
                        if (Object.keys(changes).length > 0) {
                            Loading.waitForPromises(this.onCategoryChanged()).catch(AError.handle);
                        }
                    }
                },
            ],
        });
        await form.generate({ translate: true });
        await form.injectFormData({ formData: { category: this.categories }, triggerChange: true });
        await form.initFormValidation();
        return form;
    }
    /**
     * Creates adjustment inputs for specific category
     */
    async genCustomForm() {
        const categoryInputInits = {
            // 'Occupancy': () => '',
            // 'VisitorRate': () => '',
            // 'Compliancy': () => '',
            // 'CompliancyVisitor': () => '',
            'EnforcementIntensity': async () => {
                const updateChanges = () => {
                    try {
                        // const { propogateEvent } = Object.assign({ propogateEvent: true }, options)
                        const transformData = (target) => {
                            const { min, left, right, max } = target?.minMaxEntryCount;
                            delete target.minMaxEntryCount;
                            return Object.assign(target, { min, left, right, max });
                        };
                        // const oldData = transformData(form.formData)
                        const newData = transformData(form.extractFormData({ cleanData: true, setInternalFormData: true }));
                        // const changes = compareObjects(oldData, newData)
                        // if (Object.keys(changes).length > 0) {
                        //   AEngine.log('Changes', changes)
                        // }
                        if (this.precalc) {
                            for (let key in newData) {
                                if (newData[key] !== undefined) {
                                    this.precalc[key] = newData[key];
                                }
                            }
                            // Object.assign(this.precalc, newData)
                        }
                        this.legend.update({
                            ...this.buildProfile()
                            // transition: AConfigNew.get<AColorLerp>(`thematic.${ACamel(this.category)}` as AConfigKey)!
                        });
                        this.legend.redrawLegend();
                        this.drawStatistics();
                        // if (propogateEvent) {
                        //   this.redrawStatistics({ categoryChanged: true })
                        //   this.legend.redrawLegend()
                        // }
                    }
                    catch (err) {
                        AError.handle(err);
                    }
                };
                const { hideOutsideBounds, min, left, right, max, unit } = this.precalc || this.precalcFallback;
                const form = new AFormInstance({
                    ignoreWildcards: true,
                    formInputs: [
                        // {id: 'category', type: 'select', options: this.categories.map(({label, value}) => ({ id: value, text: label })), onChange: (input) => updateChanges(input)},
                        { id: 'minMaxEntryCount', type: 'rangeslider', min, max, unit, onChange: (input) => updateChanges() },
                        { id: 'hideOutsideBounds', label: 'Hide Outside Minimum & Maximum', type: 'checkbox', onChange: (input) => updateChanges() },
                    ],
                });
                await form.generate({ translate: true, wrapInColumns: true });
                await form.injectFormData({
                    triggerChange: false,
                    formData: {
                        hideOutsideBounds: hideOutsideBounds,
                        minMaxEntryCount: { min, left, right, max, unit }
                    },
                });
                await form.initFormValidation();
                return form;
            }
        };
        const form = (categoryInputInits.hasOwnProperty(this.category)) ? await categoryInputInits[this.category]() : undefined;
        return form;
    }
    formatTimeFrame(TimeFrame) {
        switch (TimeFrame) {
            case '*': return (v) => v;
            case 'Hourly': return (v) => (`${v}:00`);
            case 'Daily': return (v) => this.weeksTranslated[this.weeks[v]];
            case 'Monthly': return (v) => this.monthsTranslated[this.months[v - 1]];
            default: return (v) => v;
        }
    }
    async refresh() {
        const filters = FilterManager.saveExplicit();
        FilterManager.setActive(false);
        try {
            // mapHelperService.unload(this.markers, UNLOAD_OPTIONS.AllListeners)
            // this.legend?.destroy()
            // const defaultColors = this.getColors({ category })
            if (!this.legend) {
                this.legend = await genLegend({
                    map: this.map,
                    precalc: () => this.precalc,
                    formatSlider: this.formatTimeFrame,
                    // hasTimeline: true,
                    smallTitle: true,
                    profile: {
                        title: '',
                        ...this.buildProfile()
                        // transition: AConfigNew.get<AColorLerp>(`thematic.${ACamel(this.category)}` as AConfigKey)!
                    }
                });
                this.baseForm = await this.genBaseForm();
                this.legend.setControls(this.baseForm.$form());
            }
            // this.legend.setContent(base)
            // Fetch statistics from database
            const { statistics } = await AEngine.get(AStatisticsSimpleService).fetch(filters, {
                mapTo: (segmentId) => this.segmentId.map[segmentId] || null,
                ignoreDetectionsOutsideSegment: this.ignoreDetectionsOutsideSegment
            });
            this.statistics = statistics;
            // Set all entries per group to 1
            // Object.values(statistics).map(waysegment => waysegment.addEntryPerGroup())
            // const warnings: any[] = []
            // for (let wsid in this.segmentId.imap) {
            //   let segmentIds = this.segmentId.imap[wsid]
            //   if (segmentIds !== null) {
            //     if (statistics.hasOwnProperty(wsid)) {
            //       statistics[wsid].addEntryPerGroup(segmentIds.length)
            //     }
            //   } else {
            //     warnings.push(`WSID '${wsid}' to map!`)
            //   }
            // }
            // if (warnings.length) {
            //   console.warn(`Couldn't link ${warnings.length} statistics\n`, warnings)
            // }
            const { coordinates } = await this.onCategoryChanged();
            // Draw statistics and export coordinates of lines
            // await this.preCalcData({ resetPrevValues: true, statistics })
            // const { coordinates } = await this.drawStatistics()
            // this.updateLegendRange({ category })
            // this.legend.redrawLegend()
            // Fit map
            this.map.fit(coordinates);
            FilterManager.setActive(true);
        }
        catch (err) {
            AError.handle(err);
        }
    }
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column c-scroll col-12">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input" type="time" id="FromTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input" type="time" id="ToTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="AreaMulti">Area</label>
          <div id="AreaMulti" class="copycat noselect dd-disallow-none" maxlength="18">
            <span>None</span>
            <ul class="dropdown c-scroll"></ul>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label" for="ZoneMulti">Zone</label>
          <div id="ZoneMulti" class="copycat noselect dd-disallow-none" maxlength="18">
            <span>None</span>
            <ul class="dropdown c-scroll"></ul>
          </div>
        </div>

      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>
    <div class="flex-child">
      <div id="map" class="aci-map"></div>
    </div>
  `);
}
