import { AError } from "../../classes/AError.js";
import { AExportMap } from "../../classes/AExportMap.js";
import { MAP_OPTIONS } from "../../core/maps/AMapStructs.js";
import { createMap, ShowMapScans } from "../../utils/maps.js";
import { DetectionsSearchIdRanged } from "../../utils/query.js";
import { AInputDate, AInputTime } from "../../utils/tools.js";
export class APage {
    constructor() {
        this.SessionMarkers = {};
        this.map = createMap('map', {
            streetViewControl: true,
        });
        this.Markers = [];
    }
    async init() {
        FilterManager.load();
        await mapHelperService.prepareMapItems(MAP_OPTIONS.All, {
            showLegend: true,
            showSearch: true,
        }).then(_ => this.checkIfRedirected()).catch(AError.handle);
        this.map.fit();
        mapHelperService.displaySessionsOnMap({
            interpolate: false,
            sessions: this.SessionMarkers,
            map: this.map
        });
        $('#RefreshButton').on('click', _ => FilterManager.showFilterWarning().then(_ => this.refresh()));
    }
    /**
     * In the BackOFfice you can select "View On Map" in some of the reports.
     * This method will handle that functionality.
     */
    checkIfRedirected() {
        const { DetectionId, DetectionDeviceId, DetectionTime } = menuHelperService.args;
        if (DetectionId && DetectionDeviceId) {
            const FromDateTime = (typeof DetectionTime === 'string') ? new Date(DetectionTime) : DetectionTime;
            const a = AInputDate(FromDateTime);
            const b = AInputTime(FromDateTime);
            $('#FromDate').val(a);
            $('#FromTime').val(b);
            $('#DetectionId').val(DetectionId);
            $('#DetectionDeviceId').val(DetectionDeviceId);
            this.refresh();
        }
    }
    refresh() {
        const filters = FilterManager.save();
        FilterManager.setActive(false);
        mapHelperService.destroy(this.Markers);
        return Loading.waitForPromises(requestService.fetch({
            AssertValues: true,
            Name: "MapSearch",
            Query: (`
          SELECT *
          FROM (${DetectionsSearchIdRanged}) full
          WHERE ParkingRight IS NOT NULL
          ORDER BY DetectionId
          LIMIT :Limit
        `),
            Params: filters,
            Language: Language,
            Translate: ["VehicleType", "ParkingAreaType", "ParkingRightType", "IsIllegallyParked"]
        }, { cacheQuery: true })).then(async (response) => {
            FilterManager.setActive(true);
            if (this.dottedLineToPda) {
                this.dottedLineToPda.setMap(null);
            }
            if (response.Rows.length === 0) {
                return Alerts.noResults();
            }
            this.Markers = ShowMapScans({
                response,
                map: this.map, // Map reference
            });
            await detectionService.selectMarkerAndDisplayPda(this.map, response, this.Markers);
            const exportMap = new AExportMap('scans', { scales: true, markersArePolygons: true });
            exportMap.prepareCache();
            exportMap.allowExport();
            this.map.focusOnMarkers();
        }).catch(AError.handle);
    }
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column c-scroll col-12">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input" type="time" id="FromTime" required="required">
        </div>
    
        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input" type="time" id="ToTime" required="required">
        </div>
    
        <div class="form-group">
          <label class="form-label" for="DetectionDeviceId">Device Id</label>
          <input class="form-input" type="text" id="DetectionDeviceId" placeholder="00000000" maxlength="20">
        </div>
    
        <div class="form-group">
          <label class="form-label" for="DetectionId">Detection Id</label>
          <input class="form-input" type="text" id="DetectionId" placeholder="00000000" maxlength="20">
        </div>
    
        <div class="form-group">
          <label class="form-label" for="LicensePlate">LicensePlate</label>
          <input class="form-input" type="text" id="LicensePlate" placeholder="XXXXXX" maxlength="32" LicensePlate>
        </div>
    
        <div class="form-group">
          <label class="form-label" for="Limit">Max results</label>
          <input class="form-input" type="number" id="Limit" value="2000">
        </div>
      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>
    <div class="flex-child">
      <div id="map" class="aci-map"></div>
    </div>
  `);
}
