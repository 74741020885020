import { AError, ERROR_GROUPS } from "../classes/AError.js";
import { AEngine, sleep } from "../core/AEngine.js";
import { toast } from "../utils/toasts.js";
import { EVENTS } from "./AEventService.js";
import { AUnitTestService } from "./AUnitTestService.js";
export var ALERTS;
(function (ALERTS) {
    ALERTS[ALERTS["None"] = -1] = "None";
    ALERTS[ALERTS["Info"] = 0] = "Info";
    ALERTS[ALERTS["Warning"] = 1] = "Warning";
    ALERTS[ALERTS["Error"] = 2] = "Error";
    ALERTS[ALERTS["Form"] = 3] = "Form";
    ALERTS[ALERTS["Medium"] = 4] = "Medium";
    ALERTS[ALERTS["Large"] = 5] = "Large";
    ALERTS[ALERTS["Mega"] = 6] = "Mega";
})(ALERTS || (ALERTS = {}));
export var ALERT_TITLES;
(function (ALERT_TITLES) {
    ALERT_TITLES[ALERT_TITLES["None"] = 0] = "None";
    ALERT_TITLES[ALERT_TITLES["Success"] = 1] = "Success";
    ALERT_TITLES[ALERT_TITLES["Warning"] = 2] = "Warning";
    ALERT_TITLES[ALERT_TITLES["Error"] = 3] = "Error";
    ALERT_TITLES[ALERT_TITLES["Download"] = 4] = "Download";
    ALERT_TITLES[ALERT_TITLES["Info"] = 5] = "Info";
    ALERT_TITLES[ALERT_TITLES["History"] = 6] = "History";
    ALERT_TITLES[ALERT_TITLES["Export"] = 7] = "Export";
})(ALERT_TITLES || (ALERT_TITLES = {}));
export var ALERT_BUTTONS;
(function (ALERT_BUTTONS) {
    ALERT_BUTTONS[ALERT_BUTTONS["ok"] = 0] = "ok";
    ALERT_BUTTONS[ALERT_BUTTONS["okCancel"] = 1] = "okCancel";
    ALERT_BUTTONS[ALERT_BUTTONS["saveCancel"] = 2] = "saveCancel";
    ALERT_BUTTONS[ALERT_BUTTONS["retryCancel"] = 3] = "retryCancel";
    ALERT_BUTTONS[ALERT_BUTTONS["startCancel"] = 4] = "startCancel";
    ALERT_BUTTONS[ALERT_BUTTONS["createCancel"] = 5] = "createCancel";
    ALERT_BUTTONS[ALERT_BUTTONS["recalcCancel"] = 6] = "recalcCancel";
    ALERT_BUTTONS[ALERT_BUTTONS["deleteCancel"] = 7] = "deleteCancel";
    ALERT_BUTTONS[ALERT_BUTTONS["downloadCancel"] = 8] = "downloadCancel";
    ALERT_BUTTONS[ALERT_BUTTONS["yesNo"] = 9] = "yesNo";
})(ALERT_BUTTONS || (ALERT_BUTTONS = {}));
export var ALERT_STATUS;
(function (ALERT_STATUS) {
    ALERT_STATUS[ALERT_STATUS["ON_ACTION_PROCEED"] = 0] = "ON_ACTION_PROCEED";
    ALERT_STATUS[ALERT_STATUS["ON_ACTION_CANCEL"] = 1] = "ON_ACTION_CANCEL";
    ALERT_STATUS[ALERT_STATUS["ON_MODAL_CLOSED"] = 2] = "ON_MODAL_CLOSED";
})(ALERT_STATUS || (ALERT_STATUS = {}));
class AAlertInteraction {
    constructor($ele, info = {}) {
        this.$ele = $ele;
        this.events = {
            [ALERT_STATUS.ON_ACTION_PROCEED]: [],
            [ALERT_STATUS.ON_ACTION_CANCEL]: [],
            [ALERT_STATUS.ON_MODAL_CLOSED]: []
        };
        this.info = info;
    }
    on(key, method) {
        if (typeof key === 'string') {
            switch (key) {
                case 'option1':
                    key = ALERT_STATUS.ON_ACTION_PROCEED;
                    break;
                case 'option2':
                    key = ALERT_STATUS.ON_ACTION_CANCEL;
                    break;
                case 'close':
                    key = ALERT_STATUS.ON_MODAL_CLOSED;
                    break;
                default: AError.handleSilent(`Couldn't find modal option "${key}"`, ERROR_GROUPS.ModalError);
            }
        }
        if (this.events[key] !== undefined) {
            this.events[key].push(method);
        }
        else {
            console.error(`Please use one of the following events ${Object.keys(this.events)}!`);
        }
    }
    getEvents(key) {
        return this.events[key];
    }
}
export class AAlertService {
    constructor() {
        this.titleToText = {
            [ALERT_TITLES.None]: () => '',
            [ALERT_TITLES.Success]: () => this.translations['success'],
            [ALERT_TITLES.Warning]: () => this.translations['warning'],
            [ALERT_TITLES.Error]: () => this.translations['error'],
            [ALERT_TITLES.Download]: () => this.translations['download'],
            [ALERT_TITLES.Info]: () => this.translations['info'],
            [ALERT_TITLES.History]: () => this.translations['history'],
            [ALERT_TITLES.Export]: () => this.translations['export'],
        };
        this.buttonsToText = {
            [ALERT_BUTTONS.ok]: () => [this.translations['ok']],
            [ALERT_BUTTONS.okCancel]: () => [this.translations['ok'], this.translations['cancel']],
            [ALERT_BUTTONS.saveCancel]: () => [this.translations['save'], this.translations['cancel']],
            [ALERT_BUTTONS.retryCancel]: () => [this.translations['retry'], this.translations['cancel']],
            [ALERT_BUTTONS.startCancel]: () => [this.translations['start'], this.translations['cancel']],
            [ALERT_BUTTONS.createCancel]: () => [this.translations['create'], this.translations['cancel']],
            [ALERT_BUTTONS.recalcCancel]: () => [this.translations['recalculate'], this.translations['cancel']],
            [ALERT_BUTTONS.deleteCancel]: () => [this.translations['delete'], this.translations['cancel']],
            [ALERT_BUTTONS.downloadCancel]: () => [this.translations['download'], this.translations['cancel']],
            [ALERT_BUTTONS.yesNo]: () => [this.translations['yes'], this.translations['no']],
        };
        this.MAX_SHOWN = 3;
        this.queue = [];
        this.showCount = 0;
        // Before translation is done, init with english language
        this.translations = {
            'No Results Found': 'No Results Found',
            'Please make sure the dates are filled out correctly.': 'Please make sure the dates are filled out correctly.',
            'Please fill out all the dropdown menu\'s!': `Please fill out all the dropdown menu's!`,
            'Please fill out everything!': 'Please fill out everything!',
            'Please fill out all the filters!': 'Please fill out all the filters!',
            'Street View has no road for this location!': 'Street View has no road for this location!',
            'Internal Server Error': 'Internal Server Error',
            'Not Implemented Yet!': 'Not Implemented Yet!',
            'Something went wrong!': 'Something went wrong!',
            'Please contact an ACI employee for further instructions!': 'Please contact an ACI employee for further instructions!',
            'ok': 'Ok',
            'create': 'Create',
            'recalculate': 'Recalculate',
            'save': 'Save',
            'delete': 'Delete',
            'download': 'Download',
            'start': 'Start',
            'cancel': 'Cancel',
            'retry': 'Retry',
            'yes': 'Yes',
            'no': 'No',
            'success': 'Success',
            'warning': 'Warning',
            'error': 'Error',
            'info': 'Info',
            'history': 'History',
            'export': 'Export',
        };
        Object.defineProperty(window, 'Alerts', {
            get: () => this
        });
    }
    autoInit() {
        Events.h_once(EVENTS.API_READY, () => {
            this.listenToKeyboardShortcuts();
            this.prefetch().catch(AError.handle);
        });
    }
    get isVisible() {
        return $('.modal.active').length > 0;
    }
    get active() {
        return $('.modal.active').last();
    }
    isPreviousErr() { return this.active.is(`[modal-error="true"]`); }
    get $container() {
        if (!this._$container || this._$container.length === 0) {
            this._$container = $(`<div class="alerts" style="
          display: block;
          position: absolute;
          top: 60px;
          right: 0;
          width: 140px;
          height: calc(100% - 60px);
          z-index: 1001;
        "></div>
      `);
            $('.container').append(this._$container);
        }
        return this._$container;
    }
    async waitForInit() {
        while (this.loadingPromise === undefined) {
            await sleep(100);
        }
        await this.loadingPromise;
    }
    listenToKeyboardShortcuts() {
        $(document).on('keydown', (e) => {
            const catchAll = ['Enter'];
            const $focus = $(':focus');
            const $activeModal = $('.modal.active');
            const focusIsInModal = $focus.closest('.modal.active').length > 0;
            const modalIsActive = $activeModal.length > 0;
            if (modalIsActive && focusIsInModal && catchAll.includes(e.key)) {
                e.preventDefault();
                e.stopPropagation();
                $activeModal.last().find('#option1').trigger('click');
            }
        });
    }
    async prefetch() {
        this.loadingPromise = Translate.get([
            'No Results Found',
            'Please make sure the dates are filled out correctly.',
            'Please fill out everything!',
            'Please fill out all the dropdown menu\'s!',
            'Please fill out all the filters!',
            'Street View has no road for this location!',
            'Internal Server Error',
            'Not Implemented Yet!',
            'Something went wrong!',
            'Please contact an ACI employee for further instructions!',
            'ok',
            'create',
            'recalculate',
            'save',
            'delete',
            'download',
            'start',
            'cancel',
            'retry',
            'yes',
            'no',
            'success',
            'warning',
            'error',
            'info',
            'history',
            'export',
        ]);
        this.translations = await Loading.waitForPromises(this.loadingPromise);
        return true;
    }
    log(text) {
        this.addQueue(text, ALERTS.Info);
    }
    warn(text) {
        this.addQueue(text, ALERTS.Warning);
    }
    error(text) {
        this.addQueue(text, ALERTS.Error);
    }
    addQueue(text, type) {
        this.queue.push([text, type]);
        this.refresh();
    }
    displayNext(time = 200000) {
        this.showCount++;
        const entry = this.queue.shift();
        const text = entry[0];
        const type = entry[1];
        const className = Object.keys(ALERTS)[type].toLowerCase();
        const innerHtml = text.replace(/\r?\n|\r/g, '<br/>');
        const $ele = $(`<div class="${className}">${innerHtml}</div>`);
        this.$container.append($ele);
        setTimeout(_ => $ele.css({ 'display': 'block', 'opacity': 1 }), 0);
        setTimeout(_ => {
            $ele.fadeOut(400, () => {
                this.showCount--;
                $ele.remove();
                this.refresh();
            });
        }, time + 400);
        return $ele;
    }
    refresh() {
        if (this.showCount < this.MAX_SHOWN && this.queue.length) {
            this.displayNext();
        }
    }
    linkModalClass(type) {
        switch (type) {
            case ALERTS.None: return '';
            case ALERTS.Info: return '';
            case ALERTS.Warning: return '';
            case ALERTS.Error: return '';
            case ALERTS.Form: return ' modal-sm';
            case ALERTS.Medium: return ' modal-md';
            case ALERTS.Large: return ' modal-lg';
            case ALERTS.Mega: return ' modal-xl';
            default: return '';
        }
    }
    renderDialog(dialogOptions) {
        const { title, type, buttons } = dialogOptions;
        const $content = (dialogOptions.content instanceof jQuery) ? $(dialogOptions.content) : dialogOptions.content;
        const sizeClass = this.linkModalClass(type);
        const isError = (type === ALERTS.Error);
        const attributes = [
            isError ? `modal-error="true"` : ``,
        ].join(' ');
        const buttonsHtml = this.buttonsToText[buttons]();
        const btnClass = (buttons === ALERT_BUTTONS.deleteCancel) ? 'btn-error' : (type === ALERTS.Error) ? 'btn-white' : 'btn-primary';
        const optional = (buttonsHtml.length) == 1 ? '' : (`<button id="option2" class="btn btn-white btn-shadow-sm">${buttonsHtml[1]}</button>`);
        const html = ( /*html*/`
      <div class="modal${sizeClass} active" id="modal-id" ${attributes}>
        <a href="#close" class="modal-overlay" aria-label="Close"></a>
        <div class="modal-container custom-scroll">
          <div class="modal-header">
            <div class="modal-title h5 ${title.length === 0 ? 'invisible' : ''}">${title || '...'}</div>
            <a href="#" class="btn btn-clear float-right" aria-label="Close"></a>
          </div>
          <div class="modal-body">
            <div class="content"></div>
          </div>
          <div class="modal-footer">
            <button id="option1" class="btn ${btnClass} btn-shadow-sm">${buttonsHtml[0]}</button>
            ${optional}
          </div>
        </div>
      </div>
    `);
        const $modal = $(dialogOptions.skipRegex ? html : html.replace(/(\s|\r|\n)+/g, ' '));
        if (buttons === ALERT_BUTTONS.deleteCancel || type === ALERTS.Error) {
            $modal.find('.modal-container').css('border-bottom-color', 'var(--color-red-border)');
        }
        $modal.find('.content').append($content);
        return $modal;
    }
    closeModal($modal, events, action) {
        $modal.fadeOut(300, () => {
            for (let option of events.getEvents(ALERT_STATUS.ON_MODAL_CLOSED)) {
                option({ $modal: $modal, action: action });
            }
            $modal.remove();
        });
    }
    show(dialogOptions) {
        const $modal = this.renderDialog({
            title: dialogOptions.translatedTitle || this.titleToText[dialogOptions.title](),
            buttons: dialogOptions.buttons ?? ALERT_BUTTONS.ok,
            content: dialogOptions.content,
            type: dialogOptions.type || ALERTS.None,
            skipRegex: dialogOptions.skipRegex ?? false
        });
        const events = new AAlertInteraction($modal, dialogOptions.info);
        $modal.find('#option1').on('click', async (e) => {
            e.preventDefault();
            let closeModal = true;
            for (let option of events.getEvents(ALERT_STATUS.ON_ACTION_PROCEED)) {
                let res = await Promise.resolve().then(_ => option({ action: ALERT_STATUS.ON_ACTION_PROCEED }));
                if (res === false) {
                    closeModal = false;
                }
            }
            if (closeModal) {
                this.closeModal($modal, events, ALERT_STATUS.ON_ACTION_PROCEED);
            }
        });
        $modal.find('#option2').on('click', async (e) => {
            e.preventDefault();
            let closeModal = true;
            for (let option of events.getEvents(ALERT_STATUS.ON_ACTION_CANCEL)) {
                let res = await Promise.resolve().then(_ => option({ action: ALERT_STATUS.ON_ACTION_CANCEL }));
                if (res === false) {
                    closeModal = false;
                }
            }
            if (closeModal) {
                this.closeModal($modal, events, ALERT_STATUS.ON_ACTION_CANCEL);
            }
        });
        const exitButtons = $modal.find("[aria-label='Close']");
        exitButtons.on('click', e => {
            e.preventDefault();
            this.closeModal($modal, events, ALERT_STATUS.ON_MODAL_CLOSED);
        });
        if (!AEngine.get(AUnitTestService).isTesting) {
            $('body').append($modal);
        }
        $modal.find('#option1').trigger('focus');
        return events;
    }
    noStreet() {
        return Alerts.show({
            title: ALERT_TITLES.Warning,
            content: this.translations['Street View has no road for this location!'],
            info: 'NO_STREET_FOUND'
        });
    }
    internalServerError() {
        return Alerts.show({
            title: ALERT_TITLES.Warning,
            content: this.translations['Internal Server Error'],
            info: 'INTERNAL_SERVER_ERROR'
        });
    }
    noResults(opt) {
        if (opt?.calledBySystem) {
            return;
        }
        if (AEngine.isDevelopmentMode) {
            toast({ msg: this.translations['No Results Found'], timeout: 3000 });
            return;
        }
        return Alerts.show({
            title: ALERT_TITLES.Warning,
            content: this.translations['No Results Found'],
            info: 'NO_RESULTS'
        });
    }
    notImplementedYet() {
        return Alerts.show({
            title: ALERT_TITLES.Warning,
            content: this.translations['Not Implemented Yet!'],
            info: 'NOT_IMPLEMENTED_YET'
        });
    }
    somethingWentWrong() {
        return Alerts.show({
            title: ALERT_TITLES.Error,
            type: ALERTS.Error,
            content: (`
        <div class="text-center text-wrap">
          ${this.translations['Something went wrong!']}<br>
          ${this.translations['Please contact an ACI employee for further instructions!']}
        </div>
      `),
        });
    }
    incomplete() {
        return Alerts.show({
            title: ALERT_TITLES.Warning,
            content: this.translations['Please fill out everything!'],
            info: 'FORM_INCOMPLETE'
        });
    }
    incorrectDate() {
        return Alerts.show({
            title: ALERT_TITLES.Warning,
            content: this.translations['Please make sure the dates are filled out correctly.'],
            info: 'INCORRECT_DATE'
        });
    }
    emptyUnification() {
        return Alerts.show({
            type: ALERTS.Error,
            title: ALERT_TITLES.Warning,
            content: this.translations['Please fill out all the dropdown menu\'s!'],
            info: 'EMPTY_UNIFICATION_FILTER'
        });
    }
    createMinimal({ id }) {
        id = id || 'modal-id';
        const $minimal = $(`
      <div class="modal active" id="${id}">
        <a href="#close" class="modal-overlay" aria-label="Close"></a>
      </div>
    `);
        $minimal.find('a[href="#close"]').on('click', e => {
            e.preventDefault();
            $minimal.remove();
            Events.tryInvoke('MINIMAL_MODAL_CLOSE', { e });
        });
        return $minimal;
    }
    closeAllActiveModals() {
        const $modals = $('.modal.active [aria-label="Close"]');
        $modals.trigger('click');
    }
}
