import { createArray } from "../utils/tools.js";
export class AIdAllocator {
    constructor(opt = { padStart: 8 }) {
        this.opt = opt;
        this.paddingTemplate = createArray(opt.padStart ?? 0, '0').join('');
        this.currentId = opt.startId ?? 1000;
    }
    getNextId(opt) {
        return (opt?.prefix ?? '') + this._addPadding(this.currentId++, this.opt.padStart);
    }
    getNextHexId(opt) {
        return (opt?.prefix ?? '') + this._addPadding((this.currentId++).toString(16), this.opt.padStart);
    }
    _addPadding(n, len) {
        const length = len ?? this.opt.padStart ?? 0;
        let s = n.toString();
        if (s.length < length) {
            s = (this.paddingTemplate + s).slice(-length);
        }
        return s;
    }
}
