import { AError } from "../../classes/AError.js";
import { ALoopTimer } from "../../classes/ALoopTimer.js";
import { AEngine } from "../../core/AEngine.js";
import { MAP_OPTIONS } from "../../core/maps/AMapStructs.js";
import { EVENTS } from "../../services/AEventService.js";
import { ATimeService } from "../../services/ATimeService.js";
import { createMap } from "../../utils/maps.js";
const timeService = AEngine.get(ATimeService);
export class APage {
    constructor() {
        this.SessionMarkers = {};
        this.map = createMap('map', {
            streetViewControl: true
        });
        this.tracker = [];
    }
    async init() {
        Events.on(EVENTS.SESSION_UPDATE_MAP, (markers) => this.updateList(markers));
        mapHelperService.displaySessionsOnMap({
            interpolate: false,
            sessions: this.SessionMarkers,
            map: this.map
        });
        this.refreshLoop = new ALoopTimer(() => this.tickClock(), {
            loopLifeCycle: "PAGE",
            timeout: 1000
        }).start();
        await mapHelperService.prepareMapItems(MAP_OPTIONS.All, {
            allowExport: false,
            showSearch: true
        }).catch(AError.handle);
    }
    updateList(markers) {
        $('#count span').text(Object.keys(markers).length);
        const $panel = $('#firstPanel table tbody');
        $panel.empty();
        Object.keys(markers).map((key) => this.insert($panel, markers[key]));
    }
    async insert($panel, session) {
        const { data } = session;
        const { NodeName, StatusString, User, UserDisplayName, Gps } = data;
        const $found = $panel.find(`tr[NodeName="${NodeName}"]`);
        const [timeAgo, NodeNameTranslated] = await Promise.all([
            timeService.ago(new Date(), new Date(Gps.GpsTime)),
            Translate.get(NodeName)
        ]);
        if ($found.length === 0) {
            // Removed Status: `${ StatusString || status || '' }` because it didn't fit on page
            const $ele = $(`
				<tr NodeName="${NodeName}">
					<td>${NodeNameTranslated}</td>
					<td>${UserDisplayName || User}</td>
					<td>${timeAgo}</td>
				</tr>
			`);
            $ele.on('click', (e) => this.clickRow($(e.target)));
            $panel.append($ele);
        }
        else {
            console.warn(`Changing ${NodeName} status '${$found.find('td:nth-child(2)').text()}' to '${StatusString}'`);
            const $children = $found.children();
            const values = [NodeNameTranslated, UserDisplayName || User];
            values.map((updatedText, i) => $children.eq(i).text(updatedText));
            $children.last().text(timeAgo);
            // $children.each((index, ele) => $(ele).text(values[index]))
        }
    }
    async tickClock() {
        if (this.SessionMarkers) {
            await Promise.all(Object.keys(this.SessionMarkers).map(async (deviceName) => {
                const textTime = this.SessionMarkers[deviceName].data.Gps.GpsTime;
                const lastDate = new Date(textTime);
                $(`[nodename="${deviceName}"] > td:last-child`).text(await timeService.ago(new Date(), lastDate));
            }));
        }
    }
    clickRow($target) {
        const NodeName = $target.parents('tr').attr('NodeName');
        if (this.SessionMarkers.hasOwnProperty(NodeName)) {
            const teamMember = this.SessionMarkers[NodeName];
            this.map.focusOnMarker(teamMember);
            google.maps.event.trigger(teamMember, 'click');
        }
    }
}
export function render() {
    return ( /*html*/`
		<div style="position: relative; height: 100%">
			<div class="wrapper postscan lg columns col-gapless">
				<div class="column col-4" style="background: #f1f0f0; height: 100%">
					<div id="firstPanel" class="list-container" style="overflow-y: auto; height: calc(100% - 45px)">
						<table class="styled-table grid-like fw">
							<thead>
								<tr class="head noselect">
									<th>Device</th>
									<th>User</th>
									<th>Last Updated</th>
								</tr>
							</thead>
							<tbody></tbody>
						</table>
					</div>
					<div id="lastPanel" class="list-container" style="background: #f8f9fa; height: 45px">
						<div id="count" class="text spacing01">
							Viewing
							<span>0</span>
							Devices
						</div>
					</div>
				</div>
				<div class="column col-8" style="height: 100%; background: #f8f9fa">
					<div id="map" class="aci-map"></div>
				</div>
			</div>
		</div>
	`);
}
