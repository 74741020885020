import { AError } from "../../classes/AError.js";
import { COLUMN_ACTION, COLUMN_HIDDEN, COLUMN_TEXT, COLUMN_TIME } from "../../classes/AGridTypes.js";
import { ASearchHandler } from "../../classes/grid/ASearchHandler.js";
import { AFineGroupOrm } from "../../orm/AFineGroupOrm.js";
import { ALERT_BUTTONS, ALERT_STATUS, ALERT_TITLES } from "../../services/AAlertService.js";
import { AConvertToGridColumns, AConvertToGridData, AShowTable } from "../../utils/tools.js";
import { AFormInstance } from "../../core/form/AFormInstance.js";
export class APage {
    constructor() {
        this.fineGroupOrm = new AFineGroupOrm();
        this.searchHandler = new ASearchHandler();
    }
    async init() {
        $('#create-btn').on('click', (e) => this.displayCreateModal());
        Loading.waitForPromises(this.refresh());
    }
    async displayCreateModal(formData) {
        const form = new AFormInstance({
            defaultValue: formData ?? this.fineGroupOrm.emptyFineGroup,
            ignoreWildcards: false,
            formInputs: [
                { id: 'FineGroupCode', type: 'text', minlength: 1, maxlength: 50, disabled: formData !== undefined },
                { id: 'FineGroupText', type: 'text', minlength: 1, maxlength: 255 },
                { id: 'FineLimitDuration', type: 'duration' },
                { id: 'EnableFineLimitResetTime', type: 'checkbox' },
                {
                    id: 'FineLimitResetTime', type: 'time',
                    nullIfDisabled: true,
                    isDisabled: ({ formData }) => !formData.EnableFineLimitResetTime,
                },
                { id: 'FineLimitMethod', type: 'select', options: ['SuspectDeduplication', 'FineDeduplication'].map(v => ({ id: v, text: v })), },
            ]
        });
        const events = Alerts.show({
            title: ALERT_TITLES.Info,
            buttons: ALERT_BUTTONS.saveCancel,
            content: await Loading.waitForPromises(form.generate({ translate: true }))
        });
        await form.injectFormData();
        await form.initFormValidation();
        // await AForm.injectFormData($form, { formData: formData ?? this.emptyFineGroup, formInputs })
        // await AForm.initFormValidation($form, formInputs)
        console.log('editing', form.formData);
        events.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
            try {
                if (!form.validate()) {
                    Alerts.incomplete();
                    return false;
                }
                const options = form.extractFormData({ cleanData: true });
                console.log('edited', form.formData);
                // const options: AFineGroup = AForm.extractFormData($form, { ignoreWildcards: false }) as any
                const success = await Loading.waitForPromises(formData === undefined ? this.fineGroupOrm.create(options) : this.fineGroupOrm.update(options));
                if (!success) {
                    Alerts.show({
                        title: ALERT_TITLES.Error,
                        content: await Translate.get(`${options.FineGroupCode || 'Entry'} Already Exists!`)
                    });
                }
                return success;
            }
            catch (err) {
                AError.handle(err);
            }
            finally {
                Loading.waitForPromises(this.refresh());
            }
        });
    }
    async displayDeleteModal(record) {
        const alert = Alerts.show({
            translatedTitle: await Loading.waitForPromises(Translate.get('Delete Fine Group')),
            buttons: ALERT_BUTTONS.yesNo,
            content: await Loading.waitForPromises(Translate.get(/*html*/ `
        Are you sure you want to delete this Fine Group?
      `))
        });
        alert.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
            try {
                await Loading.waitForPromises(this.fineGroupOrm.delete(record));
            }
            catch (err) {
                AError.handle(err);
            }
            finally {
                Loading.waitForPromises(this.refresh());
            }
        });
    }
    async refresh() {
        try {
            validateEnforcementProcessService.validate();
            const ares = await this.fineGroupOrm.fetchAll();
            const response = ares.Original;
            // appendResponseRows(response, ['ActionEdit', 'ActionDelete'])
            ares.addColumns(['ActionEdit', 'ActionDelete']);
            this.grid = AShowTable({
                appendTo: 'bryntum-table',
                aci: {
                    resizeToFit: true,
                    flex: 1,
                },
                features: {
                    search: true
                },
                tbar: [
                    {
                        type: 'text',
                        ref: 'searchField',
                        clearable: true,
                        label: '<i class="b-icon b-icon-search"></i>',
                        showHitIndex: false,
                        listeners: {
                            // input: 'onSearchFieldInput',
                            change: 'onSearchFieldChange',
                            clear: 'onSearchFieldClear',
                            thisObj: this.searchHandler
                        }
                    },
                ],
                selectionMode: {
                    multiSelect: false
                },
                columns: AConvertToGridColumns(response, {
                    FineGroupCode: COLUMN_TEXT,
                    FineGroupText: COLUMN_TEXT,
                    FineLimitDuration: COLUMN_TIME,
                    FineLimitResetTime: COLUMN_TIME,
                    FineLimitMethod: {
                        htmlEncode: true,
                        renderer: ({ record, value }) => record['FineLimitMethodTranslated']
                    },
                    EnableFineLimitResetTime: COLUMN_HIDDEN,
                    FineLimitMethodTranslated: COLUMN_HIDDEN,
                    'ActionEdit': COLUMN_ACTION({
                        iconCls: 'fa-solid fa-pencil text-primary',
                        btnCls: 'btn-white',
                        onClick({ record }) {
                            this.displayCreateModal(record.data);
                        },
                    }),
                    'ActionDelete': COLUMN_ACTION({
                        iconCls: 'fa-regular fa-trash text-red',
                        btnCls: 'btn-white',
                        onClick({ record }) {
                            this.displayDeleteModal(record.data);
                        },
                    }),
                }),
                data: AConvertToGridData(response),
            });
            this.grid.on('search', () => this.searchHandler.applyFilter());
        }
        catch (err) {
            AError.handle(err);
        }
    }
}
export function render() {
    return ( /*html*/`
    <div id="Rapport" class="flex-child bryntum-container has-footer-2" style="width: 100%">
      <div class="fh">
        <div class="fh" style="overflow-y: auto">
          <div class="aci-tabs hidden" tabgroup="views">
            <button class="aci-tab active" tab="tab-grid-view"><span>Grid View</span></button>
            <button class="aci-tab" tab="tab-form-view"><span>Form View</span></button>
          </div>
          <div class="columns col-gapless fh">
            <div class="column col-12">
              <div tabgroup="views" tabview="tab-grid-view" class="fh">
                <div id="bryntum-table" class="fh"></div>
              </div>
              <div tabgroup="views" tabview="tab-form-view" class="fh" style="overflow-y: auto;">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div tabgroup="views" tabview="tab-grid-view">
        <div class="columns footer aci">
          <div class="column col-2">
            <div id="count" class="text">Viewing <span>0</span> Fine Groups</div>
          </div>
          <div class="column col-2 col-ml-auto">
            <button id="create-btn" class="btn btn-primary col-12">Create Fine Group</button>
          </div>
        </div>
      </div>
    </div>
  `);
}
