import { AError } from "../../classes/AError.js";
import { ATableFormatter } from "../../core/form/table/ATableFormatter.js";
import { MAP_OPTIONS } from "../../core/maps/AMapStructs.js";
import { createMap, getCenterAny } from "../../utils/maps.js";
import { asyncMapArray } from "../../utils/tools.js";
export class APage {
    constructor() {
        // public geoObjectsVisible: Partial<{ [key in AGeoTypeStr]: boolean }> = {}
        this.SessionMarkers = {};
        this.DEFAULT_VISIBLE = true;
        this.map = createMap('map', {
            streetViewControl: true,
        });
    }
    async init() {
        FilterManager.load();
        FilterManager.setActive(false);
        await Promise.all([
            mapHelperService.prepareMapItems(MAP_OPTIONS.All - MAP_OPTIONS.ParkingSpace - MAP_OPTIONS.SplitParkingSpace, {
                allowExport: false,
                showSearch: true,
                // createToggleItems: false,
                // showOneScale: true
            })
        ]);
        await mapHelperService.toggle(this.map, MAP_OPTIONS.ParkingSpace, undefined, this.DEFAULT_VISIBLE);
        this.map.fit();
        mapHelperService.displaySessionsOnMap({
            interpolate: false,
            sessions: this.SessionMarkers,
            map: this.map
        });
        $('#RefreshButton').on('click', _ => FilterManager.showFilterWarning().then(_ => this.refresh()));
        FilterManager.setActive(true);
    }
    /**
     * Overrides the infowindow for polygons
     */
    overridePolygonInfoWindow({ ref, copied, data }) {
        console.log({ ref, copied, data });
        if (data.geoType && copied.GeoType && data.geoType === copied.GeoType) {
            delete copied.GeoType;
        }
        return {
            data: data,
            // combineResults: true,
            tableFormatter: new ATableFormatter({
                'AmountOfDetections': { type: 'TEXT' },
                'id': { type: 'TEXT' },
                'area': { type: 'TEXT' },
                'scale': { type: 'HIDDEN' },
                'Attributes': { type: 'HIDDEN' }
            })
        };
    }
    getVisibility(hidingUsedParkingSpaces, data) {
        if (hidingUsedParkingSpaces) {
            return data.AmountOfDetections === undefined || data.AmountOfDetections === 0;
        }
        else {
            return data.AmountOfDetections > 0;
        }
    }
    refresh() {
        // TODO: Reimplement this page
        // return Loading.waitForPromises(sleep(200))
        const filters = FilterManager.save();
        FilterManager.setActive(false);
        // mapHelperService.destroy(this.markers)
        const showUsedParkingSpaces = (filters.ParkingSpaceEnum === 'used');
        console.log({ showUsedParkingSpaces });
        return Loading.waitForPromises(requestService.query({
            Query: ( /*SQL*/`
            SELECT
              ps.ParkingSpaceId,
              'Unknown' as AreaName,
              LastDetectionTime,
              AmountOfDetections
            From geo_parkingspaces ps     
            LEFT JOIN (
              Select MAX(DetectionTime) as LastDetectionTime, ParkingSpaceId, COUNT(dg.ParkingSpaceId) as AmountOfDetections
              FROM detections 
              LEFT JOIN detections_geo_view dg USING (DetectionId, DetectionDeviceId)
              WHERE DetectionTime BETWEEN :FromDate AND :ToDate
              GROUP BY ParkingSpaceId
            ) d
            USING (ParkingSpaceId)  
            WHERE ps.Active=true AND ps.Visible=true AND ps.Bounds IS NOT NULL AND ParkingSpaceId IS NOT NULL AND AmountOfDetections IS NOT NULL
          `),
            Params: filters
        })).then(async (response) => {
            FilterManager.setActive(true);
            if (response.Rows.length === 0) {
                mapHelperService.toggle(this.map, MAP_OPTIONS.ParkingSpace, undefined, this.DEFAULT_VISIBLE).catch(AError.handle);
                // this.showParkingSpaces(this.DEFAULT_VISIBLE).catch(AError.handle)
                return;
            }
            const indexes = requestService.indexes(response, [
                'ParkingSpaceId',
                'AreaName',
                'LastDetectionTime',
                'AmountOfDetections'
            ]);
            const geoObjects = mapHelperService.geoObjectMapper(MAP_OPTIONS.ParkingSpace);
            // for (const row of response.Rows) {
            await Loading.waitForPromises(asyncMapArray(response.Rows, 10, async (row) => {
                // response.Rows.map((row) => {
                //  const AreaName = row[indexes['AreaName']]
                const ParkingSpaceId = row[indexes['ParkingSpaceId']];
                const AmountOfDetections = row[indexes['AmountOfDetections']];
                const { RefList } = geoObjects[ParkingSpaceId];
                for (let ref of RefList) {
                    Object.assign(ref.data, { AmountOfDetections });
                    ref.setOptions({
                        visible: false
                    });
                }
            }));
            let bounds = new google.maps.LatLngBounds();
            mapHelperService.getGeoInstancesOnMap(this.map, MAP_OPTIONS.ParkingSpace).map((parkingSpace) => {
                const position = getCenterAny(parkingSpace);
                const visible = this.getVisibility(!showUsedParkingSpaces, parkingSpace.data);
                if (visible) {
                    const center = geoObjects[parkingSpace.data.id].Center;
                    bounds.extend(center || position);
                }
                parkingSpace.setOptions({ visible });
            });
            this.map.fit(bounds);
        }).catch(AError.handle);
    }
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column c-scroll col-12">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input" type="time" id="FromTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input" type="time" id="ToTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="ParkingSpaceEnum">Display</label>
          <select class="form-select" id="ParkingSpaceEnum">
            <option value="used">Used Parking Spaces</option>
            <option value="unused">Unused Parking Spaces</option>
          </select>
        </div>
      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>
    <div class="flex-child">
    <div id="map" class="aci-map"></div>
    </div>
  `);
}
