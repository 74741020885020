import { AError } from "../classes/AError.js";
import { AIdAllocator } from "../core/AIdAllocator.js";
import { sleep } from "../core/AEngine.js";
import { ATimeoutError } from "../core/errors.js";
import { _getEle, _getEle$ } from "../utils/maps.js";
import { EVENTS } from "./AEventService.js";
export class ALoadingService {
    constructor() {
        this.promiseIdAllocator = new AIdAllocator();
        this.amountOfYields = 0;
        this.promises = [];
        this.promiseMap = {};
        this.logging = false;
        Object.defineProperty(window, 'Loading', {
            get: () => this
        });
    }
    autoInit() {
        Events.h_once(EVENTS.API_READY, _ => {
            this.translateText().catch(AError.handle);
        });
    }
    translateText() {
        return Translate.get('loading')
            .then((loading) => $('#loading > span').text(loading))
            .then(() => this.loaded = true);
    }
    async waitForEvent(keyOrKeys, showLoading = true) {
        const type = keyOrKeys.constructor.name;
        try {
            if (showLoading === true && this.amountOfYields++ == 0) {
                this.showElement();
            }
            let results;
            switch (type) {
                case STRING:
                    results = await this.waitForSingleEvent(keyOrKeys);
                    break;
                case ARRAY:
                    const resultsArr = await Promise.all(keyOrKeys.map((key) => this.waitForSingleEvent(key)));
                    results = {};
                    for (let i in resultsArr) {
                        if (resultsArr.hasOwnProperty(i))
                            results[results[i].Name] = resultsArr[i];
                    }
                    break;
                default:
                    throw new Error(`Type '${type}' not supported! Expected ${STRING} or ${ARRAY}`);
            }
            if (showLoading === true && --this.amountOfYields == 0) {
                this.hideElement();
            }
            return results;
        }
        catch (err) {
            if (showLoading === true) {
                this.reset();
            }
            throw err;
        }
    }
    /**
     * Add key to the loading queue
     * @param {*} key event key
     * @returns {Promise}
     */
    waitForSingleEvent(key) {
        return new Promise((resolve, reject) => {
            Events.once(key, (p1) => {
                if (p1?.constructor?.name === `Error`)
                    return reject(p1);
                return resolve(p1);
            });
        });
    }
    /**
     * // TODO: Implement promise timeouts
     */
    awaitTimeout(promise, msTimeout) {
        return new Promise((resolve, reject) => {
            let finished = false;
            sleep(msTimeout).then(() => {
                if (!finished) {
                    finished = true;
                    console.log('promise.timeout');
                    reject(new ATimeoutError(`Timed Out!`));
                }
            });
            promise.then((data) => {
                if (!finished) {
                    finished = true;
                    console.log('promise.finished');
                    resolve(data);
                }
            }).catch((err) => {
                if (!finished) {
                    console.log('promise.catch');
                    finished = true;
                    reject(err);
                }
            });
        });
    }
    // waitForPromises<T=any>(promises: PromiseLike<T>[]): PromiseLike<[T]>;
    /**
     * Add promise / promises to the loading queue
     */
    async waitForPromises(promise, opt) {
        if (opt?.hideLoading !== true && this.amountOfYields++ == 0) {
            this.showElement();
        }
        const oneResultSet = !Array.isArray(promise);
        const promises = (oneResultSet === true) ? [promise] : promise;
        let stackRef = new Error();
        if (this.logging) {
            this.promises.push(...(promises.map(p => ({ p, stack: stackRef.stack }))));
            this.promises.map(({ p, stack }, i) => {
                this.promiseMap[i] = { p, stack };
                Promise.resolve().then(() => {
                    delete this.promiseMap[i];
                    return p;
                }).catch(err => {
                    if (this.promiseMap[i])
                        this.promiseMap[i].stack = (err instanceof Error) ? err.stack : stackRef.stack;
                });
            });
        }
        const $ele = this.genBlockAction(opt);
        let allPromise = Promise.all(promises.map(p => {
            return Promise.resolve().then(() => p).catch(err => Promise.reject(err));
        }));
        const id = this.promiseIdAllocator.getNextId();
        this.promiseMap[id] = { p: allPromise, stack: stackRef.stack };
        return await allPromise.then((results) => {
            this.amountOfYields = Math.max(this.amountOfYields - 1, 0);
            if (opt?.hideLoading !== true && this.amountOfYields == 0) {
                this.hideElement();
            }
            if ($ele) {
                $ele.fadeOut(300);
                sleep(300).then(() => $ele.remove());
            }
            return oneResultSet ? results[0] : results;
        }).catch(err => {
            this.reset();
            if (this.logging) {
                console.error(err);
            }
            throw err;
        }).finally(() => {
            delete this.promiseMap[id];
        });
    }
    reset() {
        this.amountOfYields = 0;
        this.hideElement();
    }
    // Show dom element
    showElement() {
        $('#loading:not(.show)').addClass('show');
    }
    // Hide dom element
    hideElement() {
        $('#loading.show').removeClass('show');
    }
    genBlockAction(opt) {
        const useBlocking = opt && opt.blockParent && _getEle(opt.blockParent);
        if (useBlocking) {
            const $ele = $(/*html*/ `
      <div class="loading-page-init loading-page-persistent">
        <div class="loading-circular size-xxl text-primary" style="--fa-animation-duration: 1s; --fa-thickness: 0.3rem; height: 100%;"></div>
      </div>
      `);
            $ele.appendTo(_getEle$(opt.blockParent));
            return $ele;
        }
        return $();
    }
}
